import axios from "axios";
import { configuration } from "../helpers/config";

const tomorrow = new Date(Date.now() + 3600 * 1000 * 24);
export const formApiHandler = async (data, dealer_code) => {
  try {
    const res = await axios.post(
      configuration.FORM_URL,
      {
        ...data,
        country: configuration.FORM_COUNTRY_CODE, //fixed
        source: "Web", //fixed
        // utm_source_id: "1664257076611", //fixed
        // email_reminder_date: tomorrow, // 1 day after
      },
      {
        headers: {
          "x-api-key": configuration.FORM_API_KEY,
          dealer_code: dealer_code,
          // url: window.location.origin,   // AS THIS IS GIVING CORS ERROR
        },
      },
    );
    if (res.data.error === "0") {
      return {
        message: "success",
        responseData: res?.data,
      };
    }
  } catch (err) {
    return err;
  }
};

//when send to dealer is clicked on configurator page starts
//mail is sent to dealer and customer
export const send_to_dealer_mail_configurator = async (data) => {
  try {
    const res = await axios.post(
      // "http://localhost:7000/api-website/configurator/maildealer",
      `${configuration.APP_URL_PRODUCTS}configurator/maildealer`,
      {
        ...data,
      },
    );
    // if (res.data.error === "0") {
    //   return {
    //     message:"success",
    //     responseData: res?.data
    //   };
    // }
  } catch (err) {
    // console.log("error in sending mail dealer---->",err.message)
    return err;
  }
};

//when send to dealer is clicked on configurator page ends
