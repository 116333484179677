import "react-responsive-modal/styles.css";
import React, { useEffect, useState } from "react";
import FloatBar from "../floating-menu/float-bar";
import Footer from "./footer";
import Header from "./header";
import axios from "axios";
import { configuration } from "../../helpers/config";
import { useDispatch, useSelector } from "react-redux";
import { formNames } from "../forms/Validation";
import { toggleForm } from "../../redux/ActionCreator";
import { useRouter } from "next/router";
import DynamicForm from "../forms/dynamic-form/dynamic-form";
import {
  IS_DEALER_SELECTED,
  SELECTED_DEALER,
  PERSONAL_IDENTIFIABLE_INFORMATION_FORM,
  IS_DEALER_WEBSITE,
  UPDATE_DEALER_CODE,
  UPDATE_LOB_VALUE,
} from "../../redux/ActionTypes";

import Script from "next/script";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import Head from "next/head";

// export default function Layout({ children }, props) {
const Layout = ({ children,menu,footer }) => {
  const formModals = useSelector(({ app }) => app.formModals);
  const isLobPage = useSelector(({ app }) => app.isLobPage);
  const dispatch = useDispatch();
  const router = useRouter();
  const [data, setData] = useState([]);
  const [formsData, setFormsData] = useState([]);
  const [scriptdata, setscriptdata] = useState(null);
  const [noscriptdata, setnoscriptdata] = useState(null);
  let EXTERNAL_DATA_URL = "";
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);
  const LobValue = useSelector(({ app }) => app.LobValue);
  const reduxData = useSelector(({ app }) => app);
  let [currentUrl, setCurrentUrl]= useState("");


  const exceptionRoutes = ["/", "/car", "/bike", "/marine"];
  const getDealerDetails = async (currentUrl) => {
    const apiData = await axios.get(
      // `${configuration.APP_URL_CONTENTS}/dealer-code?url=website-d1.suzukibusinesscloud-dev.com`,
      `${configuration.APP_URL_CONTENTS}/dealer-code?url=${EXTERNAL_DATA_URL}&lob=${LobValue}`,
    );
    if (apiData?.data?.result[0]?.isDealerWebsite) {
      dispatch({
        type: UPDATE_LOB_VALUE,
        payload: apiData?.data?.result[0]?.lob,
      });
      dispatch({
        type: IS_DEALER_WEBSITE,
        payload: apiData?.data?.result[0]?.isDealerWebsite,
      });
      dispatch({
        type: UPDATE_DEALER_CODE,
        payload: apiData?.data?.result[0]?.dealerCode,
      });
    } else {
      dispatch({
        type: IS_DEALER_WEBSITE,
        payload: apiData?.data?.result[0]?.isDealerWebsite,
      });
      dispatch({
        type: UPDATE_DEALER_CODE,
        payload: apiData?.data?.result[0]?.dealerCode,
      });
    }
  };
  useEffect(() =>{
    if(window){
      EXTERNAL_DATA_URL = window.location.origin;
      setCurrentUrl(window.location.origin + router.asPath)
    }
  },[])

  useEffect(() => {
    // Get the current domain URL without the path
    const currentBaseUrl = window.location.host;

    if (configuration.WEBSITE_TYPE !== "Distributor") {
      getDealerDetails(currentBaseUrl);
    }
  }, [router.pathname]);
  // fetch forms data on mounting of page
  useEffect(() => {
    if (!LOB_VALUE) return; 
    if (reduxData.dealerCode != "") {
      (async () => {
        try {
          if(LOB_VALUE!==null){
           const res = await axios.get(
            `${configuration.APP_URL_CONTENTS}/forms?lob=${LobValue}&dealer_code=${reduxData.dealerCode === undefined ? null : reduxData.dealerCode}`,
             configuration.HEADER_DEALER_LOCATOR_VAPT,
          );
          setFormsData(res.data[0]?.forms);
        }
        } catch (e) {
          // console.log(e.message);
        }
      })();
    }
  }, [reduxData.dealerCode,LobValue]);

  // fetch settings config from API on mounting
  useEffect(() => {
    if (!LOB_VALUE) return; 
    if (reduxData.dealerCode != "") {
      (async () => {
        try {
          if(LOB_VALUE!==null){
          const res = await axios.get(
            `${configuration.APP_URL_CONTENTS}/settings?lob=${LobValue}&dealer_code=${reduxData?.dealerCode}`,
          );
                setData(res.data);
        }
        } catch (err) {
          // console.log(err.message);
        }
      })();
    }
  }, [reduxData.dealerCode,LobValue]);

  //get data from s3 bucket for script in head.js
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${configuration.IMG_URL}js/head.js`);
        const result = await response.json();
        setscriptdata(result);
      } catch (error) {
      }
    })();
  }, []);

  // get data from s3 bucket for noscript in body.js
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${configuration.IMG_URL}js/body.js`);
        const result = await response.json();
        setnoscriptdata(result);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    })();
  }, []);

  useEffect(() => {
    if (typeof document !== "undefined") {
      noscriptdata && noscriptdata.length > 0
        ? document.body.insertAdjacentHTML("afterbegin", noscriptdata)
        : null;
    }
  }, [noscriptdata]);
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
      window.getAppConfiguration = () => {
        try{
          return{
            "allowMicrophone": false,
            "isHome":false,
        "hideAgentName": true,
            "enableLoader":true,
            "disableTimerMessages":true,
        "host": "cometd.simplify360.com",
          "loghost": "data3.simplify360.com",
          "token": "ejlCQTUveFFKejl3VnA1VUNBaytzQT09",
              "configId": 3489,
              "anonymous": true,
              "timer": 4,
              "timerPopUp": " ",
              "timerInterval": [
                1,
                15,
                30,
                45,
                120
              ],
              "timerMessages": [
                "Hii  All",
                "Hello buddy",
                "Hii there",
                "How may I help you",
                "Do you need any help"
              ],
              "hideChatHis": true,
              "notification": {
                "isEnabled": false,
                "audio": "notification.mp3"
              },
              "configDetails": {
                "welcomeMessage": "Hi Welcome Thank you for contacting us.",
                "introductionMessage": "",
                "brandIcon": "https://attachment-downloads-prod.s3.amazonaws.com/whatsapp/Suzuki-chatbot-logo-NEW.png",
                "brandName": "Suzuki Philippines",
                "defaultBotName":"Suzuki Philippines",
                "iconHeight":"105px",
                "iconWidth": "90px",
                "iconRadius": "21px",
                "iconFontSize": 40,
                "iconFontColor": "#fff",
                "iconFont": "String",
                "iconText": "String",
                "header": "Hello!",
                "headerMessage": " ",
                "showPreviousSession": false,
                "showAgents": false,
                "showAgentInfo": false,
                "startConversationMessage": "Start a Conversation",
                "agentIndroductionMessage": "Our team typically replies in a few minutes",
                "chatHistoryIntroductionMessage": "Want to take a peek at the chat history?",
                "sessionCloseMessage": "Your conversation is closed. Thank you for contacting us!",
                "sendChatTranscript": true,
                "showForm": false,
                "skipForm":true,
                "startNewSession": true,
                "allowManualSessionEnd": false,
                "closeCoversationManually":false,
                "inActiveDuration": 120,
                "inActiveMessage": "You were inactive for more than 2 minutes now, so the session is closed. If there is still some problem existing please start a new conversation.",
                "displayFooter": true,
                "footerText": "Powered by",
                "footerBrandText": "",
                "footerLink": "https://www.simplify360.com/",
                "footerIcon": "https://attachment-downloads-prod.s3.amazonaws.com/footer.png",
                "brandMessage": "Welcome to Vianet",
                "brandWelcomeMessage": "",
                "brandSubMessage": "In case we lose you, we would love a few details about you",
                "brandChannelSubMessage": "We help you get more Engagement on your YouTube video by promoting it to Relevant Audiences using Google Ads.",
                "channelIntroText": "Talk to us on your favorite channel",
                "contactFormIntroText": "This is contact form text",
                "offline": false,
                "offlineText": "We are currently offline!",
                "offlineIntroText": "We are currently offline! Please contact us for more details",
                "noconversation": "No Conversation Found",
                "hideFAQ": true
              },
              "designDetails": {
                "closeConversationButtonBackGround":"linear-gradient(124deg, rgb(0, 90, 255) 0%, rgb(18, 24, 164) 54%, rgb(14, 86, 197) 100%, rgb(0, 212, 255) 100%)",
                 "inputBorderColor":"rgb(201 208 251)",
                "mainMenuButtonColor":"#FFF",
                "mainMenuButtonBackground":"#3F51B7",
                "mainMenubuttonBorderRadius":20,
                "loaderColor":"#3E50B5",
                "dropDownFontColor":"#3F50B6",
                "dropDownFontFamily":"Poppins",
                "dropDownFontSize":"13px",
                "dropDownFontWeight":600,
                "buttonHighlightTextColor":"#FFF",
                "buttonBackgroundColor":"#FFF",
                 "buttonTextColor":"#3E59B5",
                "buttonHighlightBackgroundColor":"#20295C",
                    "icon": "",
                    "iconHeight": 59,
                    "iconWidth": 52,
                    "iconRadius": 10,
                    "iconFont": "sans-serif;",
                    "iconFontSize": 40,
                    "iconFontColor": "#FFFFFF",
                    "messageFont": "sans-serif;",
                    "messageFontSize": 12,
                    "messageFontColor": "rgb(74, 111, 138)",
                    "senderMessageColor": "#FFF",
                    "recieverMessageColor": "#0066B3",
                    "ReceiverMessageFont": "poppins",
                    "ReceiverMessageFontSize": "13px",
                    "recieverMessageFontColor": "#fff",
                    "messageTimeFontSize": 10,
                    "messageTimeFontColor": "#3F51B7",
                    "senderMessageFontColor": "#20295C",
                    "senderFontWeight":500,
                    "SenderMessageFont": "poppins",
                    "SenderMessageFontSize": "13px",
                    "headerColor": "linear-gradient(135deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%)",
                    "headerTopColor": "linear-gradient(135deg, rgb(242, 245, 251) 0%, rgb(242, 245, 251) 100%)",
                    "headerBackgroundImage": "",
                    "headerHeight": 250,
                    "headerFont": "poppins",
                    "headerFontSize": 22,
                    "headerFontColor": "#1F175C",
                    "headerMessageFont": "poppins",
                    "headerMessageFontSize": 20,
                    "headerMessageFontColor": "#FFFFFF",
                    "selectionButtonColor": "linear-gradient(135deg, rgb(48, 71, 236) 0%, rgb(15, 34, 167) 100%)",
                    "selectionButtonTextColor": "rgb(74 111 138 / 75%)",
                    "selectionButtonFont": "poppins",
                    "selectionButtonFontSize": "13px",
                    "pageBackgroundColor": "#FFFFFF",
                    "selectionButtonsvgColor": "rgb(255, 255, 255)",
                    "selectionButtonsvgHeight": "50px",
                    "selectionButtonsvgWidth": "50px",
                    "selectionButtonBkgColor": "rgb(39 63 216)",
                    "selectionButtonTextBkgColor": "#FFFFFF",
                    "innerButtonSVGColor": "rgb(39 63 216)",
                    "convHeaderFontSize": 14,
                    "convHeaderFont": "poppins",
                    "footerFontColor": "#AAAAAA",
                    "footerFont": "sans-serif",
                    "footerFontSize": 12,
                    "brandHeight": "60px",
                    "brandRadius": "10px",
                    "brandWidth": "60px",
                    "messageCloseTextFontColor": "#DC143C",
                    "formButtonText": "Submit",
                    "formButtonBackground": "rgb(31, 23, 92)",
                    "formButtonColor": "#fff",
                    "subHeaderFont": "poppins",
                    "subHeaderFontSize": 12,
                    "subHeaderFontColor": "rgba(74, 111, 138)",
                    "convHeaderMessageFontColor": "rgb(31, 23, 92)",
                    "listItemFontColor": "rgb(39 63 216)",
                    "paddingText": "7px 10px",
                    "launchIcon": "https://attachment-downloads-prod.s3.amazonaws.com/whatsapp/Suzuki-chatbot-logo-NEW.png",
                    "launchIconHeight": "60px",
                    "launchIconWidth": "40px",
                    "contactFormButtonText": " ",
                    "launchFooterHeight": ""
                  },
                  "popupMessage": [
                    {
                      "time": "00:00:05",
                      "text": "Hii All"
                    },
                    {
                      "time": "00:00:15",
                      "text": "Hello buddy"
                    },
                    {
                      "time": "00:01:00",
                      "text": "Welcom to chatbot"
                    },
                    {
                      "time": "00:01:05",
                      "text": "How may I help you"
                    },
                    {
                      "time": "00:01:15",
                      "text": "How may I help you"
                    }
                  ],
                  "form": [
                    {
                      "selposition": 1,
                      "displayName": true,
                      "selmultiple": false,
                      "active": true,
                      "primaryField": true,
                      "updatedOn": "2024-08-22 00:25:33.0",
                      "label": "Phone Number",
                      "createdOn": "2024-08-22 00:25:33.0",
                      "required": false,
                      "webId": 441,
                      "seltype": "txtBox",
                      "id": 1353,
                      "validate": "None"
                    },
                    {
                      "selposition": 2,
                      "displayName": false,
                      "selmultiple": false,
                      "active": true,
                      "primaryField": true,
                      "updatedOn": "2024-08-22 00:25:33.0",
                      "label": "Name",
                      "createdOn": "2024-08-22 00:25:33.0",
                      "required": false,
                      "webId": 441,
                      "seltype": "txtBox",
                      "id": 1354,
                      "validate": "None"
                    }
                  ],
                  "contactForm": [
                    {
                      "selposition": 1,
                      "displayName": true,
                      "selmultiple": false,
                      "primaryField": true,
                      "active": true,
                      "updatedOn": "2021-03-08 08:53:45.0",
                      "label": "Phone Number",
                      "createdOn": "2021-03-08 08:53:45.0",
                      "required": false,
                      "webId": 210,
                      "seltype": "txtBox",
                      "id": 568,
                      "validate": "None"
                    },
                    {
                      "selposition": 1,
                      "displayName": false,
                      "selmultiple": false,
                      "primaryField": true,
                      "active": true,
                      "updatedOn": "2021-03-08 08:53:45.0",
                      "label": "Name",
                      "createdOn": "2021-03-08 08:53:45.0",
                      "required": false,
                      "webId": 210,
                      "seltype": "txtBox",
                      "id": 569,
                      "validate": "None"
                    }
                  ],
                  "showReset":false,
                  "newConversation": true,
                  "autoPopup":false,
                  "forceCloseButton":false,
                  "showGif": false,
                  "showLogo": true,
                  "newConversation": true,
                    "hideAttachment":true,
                    "autoFullScreen":false,
                    "chatRetry":false,
                    "showFooter":true
          }
        }catch(err){
          console.log("errr in simplyFyChat",err)
        }
     
      };

      if (window.Simplify360Chat) {
        window.Simplify360Chat.init(window.getAppConfiguration());

      } else {
        console.error("Simplify360Chat is not defined");
      }

      // Polling to check if Simplify360Chat is defined
      const interval = setInterval(() => {
          if (window.Simplify360Chat) {
            window.Simplify360Chat.init(window.getAppConfiguration());
            console.log("Simplify360Chat initialized in polling");
              clearInterval(interval); // Stop polling once initialized
          }
      }, 100); // Check every 100ms
    `;
    script.id = "360chat";
    document.head.appendChild(script);
  },[]);

  const ManageCookieComponent = () => {
    return (
      <>
        <Script src={configuration.CMP_FLAG_SCRIPT} />
      </>
    );
  };
  currentUrl = currentUrl.split('?')[0]; // This removes the query parameters
    //meta pixel in useEffect

    useEffect(() => {
      try {
        // Ensure this runs only in the browser
        if (typeof window !== "undefined" && configuration.COUNTRY_CODE == "IDN") {
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          );
          fbq("init", "1019006905126571");
          fbq("track", "PageView");
        }
      } catch (err) {
        console.log("error pixel event", err);
      }
    }, []);
    
       //new gtm using useEffect for optimization
       useEffect(() => {
        if (scriptdata && scriptdata.length > 0) {
          const script = document.createElement("script");
          script.innerHTML = scriptdata;
          script.defer = true; // Ensures the script executes after HTML parsing
          document.head.appendChild(script); // Append script to <head>
       
          return () => {
            document.head.removeChild(script); // Cleanup when component unmounts
          };
        }
      }, [scriptdata]);

  return (
    <>
      <Head>
           {/* new chat bot integration */}
           {
            configuration?.COUNTRY_CODE=="PH"? <link
            rel="stylesheet"
            href="https://d2m39s2m8ekg0f.cloudfront.net/simplify360Style.css"
          />:""
           }
        
       {configuration?.COUNTRY_CODE=="PH"?<script
          src="https://d2m39s2m8ekg0f.cloudfront.net/Simplify360Chat.js"
          async={true}
          // defer
        ></script>:""}
       
       
        {/*new chat bot integration ends */}
        {configuration.COOKIEBOT.includes(configuration.COUNTRY_CODE) && !configuration.ENSIGHTEN.includes(configuration.COUNTRY_CODE) && (
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="75b61b48-4108-4c8e-b3f8-b9e8898424c2"
            data-blockingmode="auto"
            type="text/javascript"
          ></script>
        )}
 
        {/* {scriptdata && scriptdata.length > 0 ? (
          <script
            dangerouslySetInnerHTML={{
              __html: scriptdata,
            }}
          />
        ) : null} */}
        <link rel="canonical" href={currentUrl} />
       {/* {process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true && <link rel="preconnect" href="//d3rs4ckmrsvep5.cloudfront.net" />}
        { process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true && <link rel="preconnect" href="//d2lmjolkxrort.cloudfront.net" />}
       { process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true && <script
          src="https://d3rs4ckmrsvep5.cloudfront.net/js/environment.js"
          async={true}
        ></script>
       } */}

{configuration.COUNTRY_CODE == "IDN" && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:5117262,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
            }}
          />
        )}
      {/* meta pixedl code ends */}
      {/* org schema for idn starts */}
      {configuration.COUNTRY_CODE == "IDN" && (
              <script
              type="application/ld+json"
              id="structured-data"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(
                  {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "Suzuki Indonesia",
                    "url": "https://auto.suzuki.co.id/",
                    "logo": "https://auto.suzuki.co.id/assets/images/logo.svg",
                    "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "Jl. Raya Bekasi Km. 19 Pulogadung",
                      "addressLocality": "Jakarta Timur",
                      "postalCode": "13920",
                      "addressRegion": "DKI Jakarta",
                      "addressCountry": "ID"
                    },
                    "contactPoint": {
                      "@type": "ContactPoint",
                      "telephone": "+021-29554800", // Corrected phone format
                      "contactType": "technical support",
                      "areaServed": "ID",
                      "availableLanguage": "Indonesian"
                    },
                    "sameAs": [
                      "https://id-id.facebook.com/suzukiindonesia",
                      "https://x.com/suzukiindonesia", // Updated from X to Twitter
                      "https://www.instagram.com/suzuki_id/",
                      "https://www.youtube.com/user/SuzukiID"
                    ]
                  }
              )
              }}
            />
        )}
        {/* org schema for idn ends */}
             {/* mexico schema tag start */}
             {configuration?.COUNTRY_CODE=="MEX"?
         <>
          <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Suzuki Mexico",
              "alternateName": "Suzuki Mexico Autos",
              "url": "https://autos.suzuki.com.mx",
              "logo": "https://autos.suzuki.com.mx/assets/images/logo.svg",
              "sameAs": [
                "https://www.facebook.com/SuzukiAutosMexico",
                "https://www.instagram.com/suzuki_mex/",
                "https://www.tiktok.com/@suzuki_mex",
                "https://www.youtube.com/user/Suzukimexico"
              ]
            }
          ) }}
        />
         </>:""}
       {/* mexico schema tag ends */}
          {/* org schema for ro starts */}
          {configuration.COUNTRY_CODE == "RO" && (
       <script
       type="application/ld+json"
       id="structured-data"
       dangerouslySetInnerHTML={{
         __html: JSON.stringify({
           "@context": "https://schema.org",
           "@type": "AutoDealer",
           "name": "Suzuki Romania",
           "url": "https://auto.suzuki.ro/",
           "contactPoint": {
             "@type": "ContactPoint",
             "telephone": "0314251091",
             "contactType": "customer service",
             "areaServed": "RO",
             "availableLanguage": "Romanian"
           },
           "sameAs": [
             "https://www.facebook.com/suzukirom",
             "https://www.instagram.com/suzuki.ro/",
             "https://www.youtube.com/@RomaniaSuzuki",
             "https://www.linkedin.com/company/suzuki-romania/"
           ]
         })
       }}
     />
     
        )}
        {/* org schema for ro ends */}
             {/*schema tag vnm starts */}
       {configuration?.COUNTRY_CODE=="VNM"?
            <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(
              {
                "@context": "https://schema.org",
              
                "@type": "AutoDealer",
              
                "name": "Việt Nam Suzuki",
              
                "image": `${process.env.NEXT_PUBLIC_IMG_URL}favicon.ico`,
              
                "url": "https://suzuki.com.vn",
              
                "telephone": "+84 251 3838 707",
              
                "priceRange": "visit website for more information",
              
                "address": {
                  "@type": "PostalAddress",
              
                  "streetAddress": "Road No. 2, Long Industrial Park Binh, Bien Hoa, Dong Nai",
              
                  "addressLocality": "Ho Chi Minh City",
              
                  "postalCode": "",
              
                  "addressCountry": "VN"
                },
              
                "sameAs": ["https://www.facebook.com/VietnamAutoSuzuki"]
              }
          ) }}
          />
       :""}
       {/*schema tag vnm ends */}
            {/* indonesia pixel code starts */}
            {configuration.COUNTRY_CODE === "IDN" && (
          <>
            {/* indonesia pixel code starts */}
                 <script
                   dangerouslySetInnerHTML={{
                     __html: `
                       !function(f,b,e,v,n,t,s) {
                         if(f.fbq) return;
                         n=f.fbq=function() {
                           n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
                         };
                         if(!f._fbq) f._fbq=n;
                         n.push=n;
                         n.loaded=!0;
                         n.version='2.0';
                         n.queue=[];
                         t=b.createElement(e);
                         t.async=!0;
                         t.src=v;
                         s=b.getElementsByTagName(e)[0];
                         s.parentNode.insertBefore(t,s)
                       }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
                       fbq('init', '1019006905126571');
                       fbq('track', 'PageView');
                     `,
                   }}
                 />
                 <noscript>
                   <img
                     height="1"
                     width="1"
                     style={{ display: 'none' }}
                     src="https://www.facebook.com/tr?id=1019006905126571&ev=PageView&noscript=1"
       />
     </noscript>
     {/* indonesia pixel code ends */}
   </>
	 )}
       {/* indonesia pixel code ends */}
       <link rel="icon" href={`${configuration.IMG_STATIC_URL}favicon.ico`}  type="image/x-icon" />
<link rel="shortcut icon" href={`${configuration.IMG_STATIC_URL}favicon.ico`}  type="image/x-icon" />
      </Head>
      {/* <body>
        {noscriptdata && noscriptdata.length > 0 ? (
          <noscript
            dangerouslySetInnerHTML={{
              __html: noscriptdata,
            }}
          />
        ) : null}
      </body> */}
      <div>
        {/*// all form components with their respective conditions */}
        {formModals.testDrive && (
          <DynamicForm
            open={formModals.testDrive}
            onClose={() => {
              dispatch(toggleForm(formNames.TEST_DRIVE));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            //filter specific form data from all forms data
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.TEST_DRIVE,
                  )[0]
                : []
            }
          />
        )}

        {formModals.testDrive_2w && (
          <DynamicForm
            open={formModals.testDrive_2w}
            onClose={() => {
              dispatch(toggleForm(formNames.TEST_DRIVE_2W));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.TEST_DRIVE_2W,
                  )[0]
                : []
            }
          />
        )}
        {formModals.brochure && (
          <DynamicForm
            open={formModals.brochure}
            onClose={() => {
              dispatch(toggleForm(formNames.BROCHURE));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            is_share_brochure="is_share_brochure"
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.BROCHURE,
                  )[0]
                : []
            }
          />
        )}
        {formModals.brochure_2w && (
          <DynamicForm
            open={formModals.brochure_2w}
            onClose={() => {
              dispatch(toggleForm(formNames.BROCHURE_2W));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            is_share_brochure="is_share_brochure"
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.BROCHURE_2W,
                  )[0]
                : []
            }
          />
        )}

        {formModals.finance && (
          <DynamicForm
            open={formModals.finance}
            onClose={() => {
              dispatch(toggleForm(formNames.CALC_PAYMENT));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.CALC_PAYMENT,
                  )[0]
                : []
            }
          />
        )}

        {formModals.enquiry && (
          <DynamicForm
            open={formModals.enquiry}
            onClose={() => {
              dispatch(toggleForm(formNames.ENQUIRY));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.ENQUIRY,
                  )[0]
                : []
            }
          />
        )}

        {formModals.enquiry_2w && (
          <DynamicForm
            open={formModals.enquiry_2w}
            onClose={() => {
              dispatch(toggleForm(formNames.ENQUIRY_2W));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.ENQUIRY_2W,
                  )[0]
                : []
            }
          />
        )}
        {formModals.service && (
          <DynamicForm
            open={formModals.service}
            onClose={() => {
              dispatch(toggleForm(formNames.SERVICE));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.SERVICE,
                  )[0]
                : []
            }
          />
        )}
        {formModals.service_2w && (
          <DynamicForm
            open={formModals.service_2w}
            onClose={() => {
              dispatch(toggleForm(formNames.SERVICE_2W));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.SERVICE_2W,
                  )[0]
                : []
            }
          />
        )}

        {formModals.contact && (
          <DynamicForm
            open={formModals.contact}
            onClose={() => {
              dispatch(toggleForm(formNames.CONTACT_US));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.CONTACT_US,
                  )[0]
                : []
            }
          />
        )}

        {formModals.getQuote && (
          <DynamicForm
            open={formModals.getQuote}
            onClose={() => {
              dispatch(toggleForm(formNames.GET_QUOTE));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.GET_QUOTE,
                  )[0]
                : []
            }
          />
        )}
        {formModals.getQuote_2w && (
          <DynamicForm
            open={formModals.getQuote_2w}
            onClose={() => {
              dispatch(toggleForm(formNames.GET_QUOTE_2W));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.GET_QUOTE_2W,
                  )[0]
                : []
            }
          />
        )}
        {formModals.pii && (
          <DynamicForm
            open={formModals.pii}
            onClose={() => {
              dispatch({ type: PERSONAL_IDENTIFIABLE_INFORMATION_FORM });
              // dispatch({ type: IS_DEALER_SELECTED, payload: false });
              // dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) =>
                      item.form_name ===
                      formNames.PERSONAL_IDENTIFICATION_INFORMATION,
                  )[0]
                : []
            }
          />
        )}
        {formModals.send_to_dealer_configurator && (
          <DynamicForm
            open={formModals.send_to_dealer_configurator}
            onClose={() => {
              dispatch({ type: "send_to_dealer_configurator" });
              // dispatch({ type: IS_DEALER_SELECTED, payload: false });
              // dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.SEND_TO_DEALER,
                  )[0]
                : []
            }
          />
        )}
        {formModals.offer_details && (
          <DynamicForm
            open={formModals.offer_details}
            onClose={() => {
              dispatch(toggleForm(formNames.OFFER_DETAIL));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.OFFER_DETAIL,
                  )[0]
                : []
            }
          />
        )}
         {Object.keys(formModals).map((formName) => {
              const predefinedForms = [
                "testDrive", 
                "brochure",
                "enquiry",
                "finance",
                "service",
                "contact",
                "getQuote",
                "pii",
                "send_to_dealer_configurator",
                "testDrive_2w",
                "brochure_2w",
                "enquiry_2w",
                "finance_2w",
                "service_2w",
                "contact_2w",
                "getQuote_2w",
              ];
            
              // Render dynamic forms for non-predefined cases
              if (!predefinedForms.includes(formName)) {
                return (
                  <DynamicForm
                    key={formName}
                    open={formModals[formName]}
                    onClose={() => {
                      dispatch(toggleForm(formName));
                      dispatch({ type: IS_DEALER_SELECTED, payload: false });
                      dispatch({ type: SELECTED_DEALER, payload: null });
                    }}
                    data={
                      formsData
                        ? formsData?.filter((item) => item.form_name === formName)[0]
                        : []
                    }
                  />
                );
              }
              return null;
            }
      
    )
	 	 	 	 }
        {/* external script for head */}
        {/* {router.pathname !== "/login" && <Header settingsData={data} />} */}
        {router.pathname !== "/login" && router.pathname !== "/404" && <Header menu={menu}/>}
        {/* <div
          dangerouslySetInnerHTML={{ __html: data[0]?.analytics?.google_tag }}
        /> */}
        <div className="layout__page--container" 
            id={LOB_VALUE == null ? "landingPage" : 
              LOB_VALUE == 2 ? "bikeHomepage" :
              LOB_VALUE == 3 ? "marineHomepage" :
              "carHomepage"} >{children}</div>
        {router.pathname !== "/login" && !isLobPage && (router.pathname !== "/" || configuration.SHOW_LOB_PAGE !== "true") &&  (
          <FloatBar formsData={formsData} />
        )}
        
        {/* {router.pathname !== "/login" && !isLobPage && router.pathname !== "/404" && LOB_VALUE !== null && (router.pathname !== "/" || configuration.SHOW_LOB_PAGE !== "true") && (
         
          // <Footer newData={data} />
          <Footer  footer={footer} />
        )} */}
         {router.pathname !== "/login" && !isLobPage && router.pathname !== "/404" &&  (router.pathname !== "/" || configuration.SHOW_LOB_PAGE !== "true") && (
           <Footer  footer={footer} />
         )}
        {/* <Script
          src={`${configuration.IMG_URL}js/body.js`}
          strategy="lazyOnload"
        /> */}
        {/* {configuration.CMP_FLAG == "true" && !isLobPage && configuration.ENSIGHTEN.includes(configuration.COUNTRY_CODE) &&(
          <ManageCookieComponent />
        )} */}
        { !isLobPage && configuration.ENSIGHTEN.includes(configuration.COUNTRY_CODE) && !configuration.COOKIEBOT.includes(configuration.COUNTRY_CODE) &&(
          <ManageCookieComponent />
        )}
        {/* chat plugin html */}

       {/* { process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true && <div
          className="dave-main-container"
          id="dave-settings"
          data-dave-components="chatbot-help,js/index.js"
          data-dave-version="9.1"
        ></div>
       } */}
       {/* { process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true && 
        <div
          className="dave-help-container"
          id="dave-help"
          data-timeout={15000}
        ></div>
       } */}
       {/* { process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true &&
        <script
          src="https://d2lmjolkxrort.cloudfront.net/assets/js/9.1/library-loader.js"
          defer={true}
          async={true}
        ></script>
       } */}
        {configuration.COOKIEBOT.includes(configuration.COUNTRY_CODE) && !configuration.ENSIGHTEN.includes(configuration.COUNTRY_CODE)&& (
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/75b61b48-4108-4c8e-b3f8-b9e8898424c2/cd.js"
          type="text/javascript"
          async
        ></script>
        )}

        {/* <div id="fb-root"></div>
        <div
          className="fb-customerchat"
          attribution="setup_tool"
          page_id="381427860277"
          theme_color="#0084ff"
        ></div> */}
      </div>
    </>
  );
};

export default Layout;
